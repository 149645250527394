body {
  background-color: rgb(0, 0, 0);
  color: azure;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.bestfont {
  font-family: "Playwrite NG Modern", cursive;
  font-size: 12px;
}

.h1Font {
  font-size: 30px;
  border-bottom: 1px solid rgb(53, 49, 49);
  font-family: "Roboto Condensed", sans-serif;
}

.App {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.developmentImage {
  width: auto;
  height: 50vh;
  /* Adjust the value as needed */
}